<template>
  <div class="Stoptusin">
    <div
        class="game-wrapper"
        :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
        { stage7: stage === 7 },
      ]"
    >

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text  green">
            <span>Ось аптека! Тобі потрібно щось від кашлю.<br>
Нам вже сусіди в батареї стукають…</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-stoptusin/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            До аптеки
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--img-circle ">
          <img src="../../assets/img/game-stoptusin/stage-2-circle.png" alt="">

          <div class="game-wrapper__stage--white-block bottom stage2">
            <p class="game-wrapper__stage--text desk blue"><span>Добрий день!<br>
Бачу, що хтось погано спав і мабуть вже не першу ніч.
Чим можу допомогти?</span></p>
            <p class="game-wrapper__stage--text mobile blue"><span>Добрий день!<br>
Бачу, що хтось погано спав і мабуть вже не першу ніч.<br>
Чим можу допомогти?</span></p>

          </div>
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            До симптомів
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text green ">Так, погано спав… увесь поверх!
            <br>
            <span>Нам потрібен препарат від сухого кашлю</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-stoptusin/stage-3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            Порадити
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--img-circle ">
          <img src="../../assets/img/game-stoptusin/stage-4-circle.png" alt="">

          <div class="game-wrapper__stage--white-block bottom stage2">
            <p class="game-wrapper__stage--text  blue"><span>Стоптусин-Тева,</span> комбінований протикашльовий
              засіб, якраз маємо в наявності</p>

          </div>
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(5)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block stage5">
          <p class="game-wrapper__stage--text green ">У нашому випадку він буде не тільки протикашльовим, але й
            заспокійливим для всіх!</p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-stoptusin/stage-5-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(6)">
          <p>
            Приборкати кашель
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 6">

        <div class="game-wrapper__stage--white-block custom-p popup stage6">

          <div class="game-wrapper__stage--top-text">
            Згадайте речі, які Вас дратують та відволікають. Це може бути, що завгодно: шум холодильника, звук
            будильника чи сигналізація на машині вранці…
            Такий самий дискомфорт може приносити кашель, що турбує не тільки пацієнта, а й оточуючих.
          </div>

          <div class="game-wrapper__stage--bottom-text">
            Спробуємо подолати кашель та знизити рівень шуму?
          </div>

          <div class="game-wrapper__stage--btn-next-stage small-m hover_type6" @click="nextStage(7)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <StoptusinGame v-if="stage === 7"></StoptusinGame>
    </div>

    <div class="notes-wrapper" v-show="stage !== 7" :class="{ active:  stage===6 }">
    <span>Інформація про лікарський засіб. РП №UA/2447/03/01. Для професійної діяльності медичних та фармацевтичних працівників.
</span>

      <span>Стоптусин-Тева (1 таблетка містить: бутамірату цитрату 4 мг, гвайфенезину 100 мг). Характеристики: таблетки круглої форми, білого кольору з лінією розлому з одного боку. Лікувальні властивості: для лікування сухого подразливого нападоподібного кашлю різного походження. Найбільш частими побічними реакціями є: головний біль, сонливість, утруднене дихання тощо.
</span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284
</span>
      <span>CBG-UA-00897. Термін дії матеріалу – 10.2024.</span>
    </div>

    <img
        v-if="
        stage === 1 || stage === 2 || stage === 3 || stage === 4 || stage === 5|| stage === 7
      "
        class="bg"
        src="../../assets/img/game-stoptusin/bg.png"
        alt=""
    />

    <img
        v-else
        class="bg"
        src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
        alt=""
    />
  </div>
</template>

<script>
import StoptusinGame from "../../components/Stoptusin-game.vue";

export default {
  name: "Stoptusin",

  data: () => ({
    stage: 1,
  }),

  components: {
    StoptusinGame,
  },

  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)

    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";


.Stoptusin {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage7 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;
        height: 110px;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.stage5 {
          height: auto;
        }

        &.bottom {
          top: initial;
          bottom: -25px;

        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;
          height: auto;


          img {
            max-width: 380px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        &.big-size {
          font-size: 24px;
        }

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          margin-top: 40px;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 274px;          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;
        background: white;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        span {
          font-family: $EB;
          display: block;
          margin-top: 10px;
          font-size: 14px;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 40px 30px 40px 30px;
        text-align: center;

        font-family: $M;
        font-size: 24px;
        line-height: 120%;
        color: white;
      }

      &--bottom-text {
        color: #424242;
        font-size: 28px;
        line-height: 120%;

        font-family: $M;

        padding: 43px 30px 33px 30px;
        text-align: center;

        span {
          font-family: $EB;
          margin-top: 10px;
          display: block;
        }

        &.mobile {
          display: none;
        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Stoptusin {
    min-height: calc((var(--vh, 1vh) * 100) - var(--height_header)) ;

    padding-top: 0;

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &__stage {

        &--white-block {

          padding: 10px;
          height: 100px;

          &.bottom {
            height: 100px;
            bottom: -15px;
          }

          &.custom-p {
          }

          &.popup {
            padding-bottom: 17px;

            img {
              max-width: 233px;
            }

          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.big-size {
            font-size: 24px;
          }

          &.small-mob {
            font-size: 20px !important;
          }

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {

          margin-top: 60px;

          img {
            margin-top: 0;
          }


          &.mr-top {
            margin-top: 0;
            padding-bottom: 18%;

            img {
              margin-top: 0;
            }
          }

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 18px 20px;
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 30px 20px 0px 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}
</style>
